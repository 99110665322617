 #background {
   width: 100%;
   height: 100vh;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
 }

 .coverAll {
   position: relative;
 }

 .backgroundImage,
 .backgroundVideo {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   object-fit: cover;
   z-index: -1;
 }

 .backgroundImage {
   display: none;
 }

 .backgroundImage,
 .backgroundVideo,
 .coverAll {
   cursor: none;
 }

 @media (max-width: 768px) {
   .backgroundImage {
     display: block;
   }

   .backgroundVideo {
     display: none;
   }
 }