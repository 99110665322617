

/* Forms */

label {
  display: block;
  margin: 5px 0;
  color: var(--custom-color-secondary);
  font-size: 0.8rem;
  text-transform: uppercase;
}

input {
  width: 100%;
  border-radius: 15px;
  border: var(--custom-border);
  text-decoration: none;
  outline: none;
  padding: 10px;
  font-size: 0.9rem;
  background-color: white ;
  color: purple;
  margin-top: 5px;
}


input[disabled] {
  color: purple;
}

/* Utils */

.block {
  display: block;
  width: 100%;
}

.inline-block {
  display: inline-block;
  width: 100%;
}

/* Button */

button,
.button {
  color: white;
  border: var(--custom-border);
  background-color: purple ;
  display: inline-block;
  text-align: center;
  padding: 0.7rem 0.7rem;
  margin:10px;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  border-radius: 15px;
}

.buttonPrimary {
  background-color: orange;
  color: white;
  border: 1px solid var(--custom-color-brand);
}

.buttonDanger{
  background-color: red;
  color: black;
  border: 0px solid var(--custom-color-brand);
}

.mainHeader {
  width: 100%;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.avatarPlaceholder {
  border: var(--custom-border);
  border-radius: var(--custom-border-radius);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-widget {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.form-widget > .button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #444444;
  text-transform: none !important;
  transition: all 0.2s ease;
}

.form-widget .button:hover {
  background-color: #2a2a2a;
}

.form-widget .button > .loader {
  width: 17px;
  animation: spin 1s linear infinite;
  filter: invert(1);
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


.login-container {
  background-color: blue;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
