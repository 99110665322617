
.aligncenter{
    text-align: center;
}

#footer-container {
    width: '100%';
    position: 'fixed';
    bottom: 0;
    width: '100%';
    background: transparent;
}

.fav-container {
    position: absolute;
    bottom: 70%;
    right: 5%;
    text-align: center;
    color: #fff;
}


#homeImg {
    opacity: .5;
}

h1.login-title {
    font-size: 100px;
    font-style: italic;
    font-family: sans-serif;
    color: #fff;
}

.login-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}


.login-options li .login-img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #fff;
    margin-bottom: 0.5em;
} .login-options li .login-img:hover {
    background-color: #ccc;
}

.login-options li p {
    color: #fff;
    font-family: sans-serif;
}


.FooterTag{
    position: fixed !important;
    background-image: url('https://res.cloudinary.com/nieleche/image/upload/v1669711201/docker_fxvrbr.png');
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent !important;
}


.FooterTag #galleryid {
    width: 70px !important;
    height: 70px !important;
    padding:0px 10px 0px 10px !important; 
}

.FooterTag #fmIcon{
    width: 108px !important;
    height: 70px !important;
    padding:0px 10px 0px 10px !important; 
}   

.FooterTag .instasec img{
    width: 70px !important;
    height: 70px !important;
    padding:0px 10px 0px 10px !important; 
}


.abouttext .abtImg{
   display: none !important;
}

.abtTypo{
    color: #fff !important;
    font-size: 10px !important;
}

.helpTypo{
    color: #fff !important;
    font-size: 10px !important;
}

.aboutimage .abtTypo{
    display: none !important;
 }

.FooterTag .css-8ci153-MuiButtonBase-root-MuiBottomNavigationAction-root {
max-width: 120px;
}
.MenuTag{
    height: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.MenuTag li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    color:#fff;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper , .css-uhb5lp{
   border-radius:11px !important;
   border: 3px solid black;
   max-width: 100% !important;
   z-index: 1;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 0px !important;
}

.DialogTags{
    display: flex !important;
    align-items: flex-start;
}

.MTright {
    float: right !important;
}

.HomeMenu {
    background-color: red !important;
    color: #fff !important;
    font-size: 10px !important;
}

.MainMenu{ 
    background-color: rgba(0, 0, 0, 0.2);
}

.css-imwso6-MuiBottomNavigationAction-label {
    color:#fff !important;
}

.padding0 {
    padding: 0px !important;
}

#aboutIcon{
    padding-top: 20px !important;
}

#fmIcon{
    padding-left: 45px;
}

#Search{
    min-width: 16px !important;
    width: 16px;
    height: 16px;
}


.flex-container{
    display: flex !important;
    justify-content: space-around;
}

.MuiDialog-root, .MuiBackdrop-root {
    height: 0; 
    width: 0;
    z-index: 100 !important; 
}

.MuiPaper-root-container {
    position: absolute !important;
    margin: 0 !important;
    top: 50%;
    left: 50%;
}

.cursor-image {
    width: 75px;
    height: 75px;
    position: absolute;
    pointer-events: none;
    z-index: 9999; /* Ensure the image is above other elements on the page */
}


@media (max-width: 768px) {
    .MuiPaper-root-container{
      width: 25em !important;
    }
  }


.MuiPaper-root {
    height: 100%; 
    border-radius:11px !important;
    border: 3px solid black;
    max-width: 100% !important;
    background-color: #FEFED8 !important;
}

.DIALOGRESIZE {
    width: 100%;
    height: 100%;
    padding: 0rem;
    resize: both !important;
    overflow: auto !important;
    padding: 20px 0px !important;
    padding-top: 0 !important;
}


p , li , button, input {
    font-family: "NeueMachinaRegular" !important;
}

h1 {
 font-family: "NeueMachinaBold" !important;
}

.css-hk6gwf-MuiImageListItemBar-subtitle ,
.css-imwso6-MuiBottomNavigationAction-label {
    font-family: "NeueMachinaRegular" !important;
}


.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modalContent {
    background: white;
    padding: 2rem;
    border-radius: 30px;
    max-width: 400px;
    width: 80%;
    text-align: center;
    color: purple;
    box-shadow: 4px 4px 2px purple;
  }

  .modalHeading {
    font-size: 1rem;
    margin: 1rem;
  }


  @media (max-width: 768px) {
    .customWindow {
      width: 25em !important;
    }
  }

  