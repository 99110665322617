
.single {
  width: 100%;
  height: 150px;
  overflow: hidden;
  cursor: pointer;
}

.single img {
  max-width: 100%;
}

.single video {
  max-width: 100%;
}

.single video:hover {
  transform: scale(1.02);
}

.single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  z-index: 9999;
}

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 45px;
  right: 45px;
}

.sliderWrap .btnPrev {
  top: 87%;
  transform: translateY(-50%);
  right: 55%;
}

.sliderWrap .btnNext {
  top: 87%;
  transform: translateY(-50%);
  left: 55%;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  align-items: center;
  justify-content: center;
}

.fullScreenImage video {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */  
}

.css-uym98a-MuiImageList-root img{
  width: 100%  !important;
  height: 100%  !important;
  object-fit: cover !important;
}


.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding-top: 0 !important;
}

.fullScreenDesc{
  bottom: 0%;
  background-color: white;
  padding: 10px;
}

.headingText{
  font-size: 1.3em;
  color: black;
}


.css-ztrqiq-MuiImageListItemBar-root {
  background: rgba(0, 0, 0, 0.2);
}