.music-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player {
    margin: 20px;
  }
  
  .time-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .play-control button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .library {
    width: 100%;
    margin-top: 20px;
  }
  
  .library-songs {
    display: flex;
    flex-wrap: wrap;
  }
  
  .library-song {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px;
  }
  
  .library-song img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .song-description {
    display: flex;
    flex-direction: column;
  }
  
  .song-details {
    width: 100%;
  }